const showForFields = [...document.querySelectorAll('[data-requires]')];

showForFields.forEach(field => {
    const configObject = JSON.parse(field.dataset.requires);

    Object.keys(configObject).forEach(key => {
        //
        // Fields are reversable if they're !
        //
        let fieldName = key;
        let mode      = true;

        if (key.indexOf('!') === 0) {
            fieldName = key.substring(1, key.length);
            mode      = false;
        }

        // Note this must be like this because of functional hidden fields
        const associatedControllingField = document.querySelector(`[name="${fieldName}"]:not([type="hidden"])`);
        const { value } = associatedControllingField;
        let associatedFieldWrapper;

        if (field.tagName.toLowerCase() == 'option') {
            associatedFieldWrapper = field;

        } else {
            associatedFieldWrapper = field.closest('.field');
        }

        if (
            (configObject[key].includes(value) && mode) || 
            (!configObject[key].includes(value) && !mode)
        ) {
            associatedFieldWrapper.style.display = 'block';
            field.disabled = false;

        } else {
            associatedFieldWrapper.style.display = 'none';
            field.disabled = true;

            if (field.selected) {
                field.closest('select').selectedIndex = -1;
            }
        }

        associatedControllingField.addEventListener('change', () => {
            if (
                (configObject[key].includes(associatedControllingField.value) && mode) ||
                (!configObject[key].includes(associatedControllingField.value) && !mode)
            ) {
                associatedFieldWrapper.style.display = 'block';
                field.disabled = false;

                if (window.selectrInstances !== 'undefined' && window.selectrInstances[fieldName] !== 'undefined') {
                    window.selectrInstances[fieldName].enable();
                } 

            } else {
                associatedFieldWrapper.style.display = 'none';
                field.disabled = true;

                if (field.selected) {
                    field.closest('select').selectedIndex = -1;
                }
            }
        });
    });
});
